/* Opening style tag for CSS */

@keyframes bouncing-loader {
    to {
      opacity: 0.1; /* Reduce opacity */
      transform: translate3d(0, -16px, 0); /* Translate vertically */
    }
  }
  
  .bouncing-loader {
    display: flex; /* Display flex for centering */
    justify-content: center; /* Align items horizontally at the center */
   }
  
  .bouncing-loader > div {
    width: 30px; /* Set width */
    height: 30px; /* Set height */
    margin: 3rem 0.2rem; /* Set margin */
    background: 
  #B8DFA2; /* Background color */
    border-radius: 50%; /* Make circular */
    text-align: center; /* Center text */
    animation: bouncing-loader 0.6s infinite alternate; /* Apply animation */
    color: white;
    padding-top: 2px;
  }
  
  .bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s; /* Delay animation */
  }
  
  .bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s; /* Delay animation */
  }
  .bouncing-loader > div:nth-child(4) {
    animation-delay: 0.6s; /* Delay animation */
  }
  .bouncing-loader > div:nth-child(5) {
    animation-delay: 0.8s; /* Delay animation */
  }
  .bouncing-loader > div:nth-child(6) {
    animation-delay: 0.10s; /* Delay animation */
  }
  .bouncing-loader > div:nth-child(7) {
    animation-delay: 0.2s; /* Delay animation */
  }
  .bouncing-loader > div:nth-child(8) {
    animation-delay: 0.4s; /* Delay animation */
  }
  .bouncing-loader > div:nth-child(9) {
    animation-delay: 0.5s; /* Delay animation */
  }